footer {
  background: black;
  height: 287.5px;
  .dna {
    margin-right: 27.5px;
    margin-top: 15px;
    width: 100px;
  }
  .header {
    max-width: 200px;
    margin-top: 16px;
    margin-bottom: 6.5px;
    width: 100%;
  }
  .column {
    &.menu {
      margin-top: 5px;
      width: calc(100% - 142.5px);
      .column:first-child {
        width: 100%;
      }
      .column:nth-child(2) {
        display: none;
      }
    }
  }
  ul {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 66px;
    vertical-align: top;
    padding: 0;
    width: 50%;
  }
  li {
    display: inline-block;
    width: 100%;
    margin-bottom: 8.5px;
    height: 13px;
    a {
      color: white;
      font: bold 11px Arial;
      letter-spacing: -0.025em;
      text-decoration: none;
      &:hover {
        color: darken(white, 15%);
      }
    }
  }
  p {
    color: #fff;
    margin: 0;
    font: normal 10px Arial;
    letter-spacing: -.025em;
    a {
      margin-left: 5px;
      color: #c7c5c5;
      &:hover {
        color: darken(#c7c5c5, 15%);
      }
    }
  }
  .reserved {
    font: normal 14px Arial;
    margin-right: 1.5px;
  }
}

@media (min-width: 600px) {
  footer {
    .dna {
      margin-right: 42.5px;
    }
    .header {
      height: 40px;
      width: auto;
    }
    .column.menu {
      margin-top: 0;
      .column:first-child {
        width: calc(95% - 195.5px);
      }
      .column:nth-child(2) {
        display: inline-block;
      }
    }
    .social {
      margin-top: 34.5px;
      margin-left: 4.5px;
      img {
        width: 191px;
      }
    }
    .social_dna {
      margin-top: 4.5px;
      margin-left: 76px;
      width: 62px;
    }
  }
}

@media (min-width: 1900px) {
  footer {
    height: 575px;
    .dna {
      width: auto;
      margin-top: 30px;
      margin-right: 85px;
    }
    .column.menu {
      width: calc(100% - 285px);
      .column:first-child {
        width: calc(95% - 391px);
      }
    }
    .header {
      max-width: 400px;
      height: auto;
      margin-top: 17px;
      margin-bottom: 13px;
    }
    ul {
      margin-top: 16px;
      margin-bottom: 132px;
    }
    li {
      margin-bottom: 17px;
      height: auto;
      a {
        font: 700 22px Arial;
      }
    }
    .social {
      margin-top: 69px;
      margin-left: 9px;
      img {
        width: auto;
      }
    }
    .social_dna {
      margin-top: 9px;
      margin-left: 152px;
      width: auto;
    }
    p {
      font-size: 20px;
      a {
        margin-left: 10px;
      }
    }
    .reserved {
      font-size: 28px;
      margin-right: 3px;
    }
  }
}
