#header {
  padding-left: 115px;
  padding-top: 11px;
  img {
    width: 114px;
  }
  .separator {
    height: 0;
    margin-top: -4px;
    border-bottom: 1px solid gray;
    margin-left: 27px;
  }
}

@media (max-width: 760px) {
  #header {
    padding-left: 10px;
    padding-top: 5px;
  }
}
