body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}
.column {
  display: inline-block;
  vertical-align: top;
  &.two {
    width: 50%;
  }
}
.page {
  margin-bottom: 70px;
}

@media (max-width: 760px) {
  .page {
    margin-bottom: 10px;
  }
}
