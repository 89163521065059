.affiliate {
  background-repeat: no-repeat;
  background-position: -36px 54px;
  background-size: 135px 244px;
  background-image: url('../../images/gene-art.png');
  min-height: 400px;
  .inner {
    max-width: 73%;
    margin: 0 auto;
    padding-top: 54px;
  }
  h1, h2 {
    text-align: center;
  }
  h1 {
    color: #787889;
    font-size: 26px;
    line-height: 27px;
    font-weight: bold;
    margin-top: 0;
  }
  h2 {
    margin-top: 22px;
    font-weight: bold;
    font-size: 18px;
  }
  #playerWrapper {
    width: 100%;
    height: 470px;
    /*
    #player {
      box-shadow: 6px 7px 5px 0px rgba(0,0,0,0.25);
      -webkit-box-shadow: 6px 7px 5px 0px rgba(0,0,0,0.25);
      -moz-box-shadow: 6px 7px 5px 0px rgba(0,0,0,0.25);
    }
    */
  }
}

@media (max-width: 760px) {

  .affiliate {
    br {
      display: none;
    }
    background: none;
    padding: 10px;
    min-height: 100px;
    .inner {
      max-width: initial;
      width: 100%;
      margin: 0px;
      padding-top: 10px;
    }
    h1 {
      font-size: 18px;
      line-height: initial;
    }
    h2 {
      font-size: 16px;
    }
    #playerWrapper {
      height: 300px;
    }
  }
}
